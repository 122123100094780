import { useContext, useEffect } from "react";
import { Location, useLocation, useNavigate } from "react-router";
import { UserContext } from "contexts/UserContext";

interface RouterLocationState {
  activeUserAccountID?: string;
}

/** Stores and retrieves the active user account id in router history as the user navigates through the Dashboard.
 * Allows Moov Admins to switch back and forth from Admin View to Facilitator View. Allows browser back and forward buttons to behave as expected. */
export default function useUserAccountHistorySynchronizer() {
  const { setActiveUserAccountIDWithSuper, activeUserAccountID, userAccounts } =
    useContext(UserContext);
  const routerLocation = useLocation() as Location<RouterLocationState>;
  const navigate = useNavigate();

  useEffect(() => {
    if (!userAccounts?.length) return;
    const locationState = routerLocation.state || {};
    const shouldStoreUserAccountID = !locationState.activeUserAccountID;
    const shouldRetrieveUserAccountID = activeUserAccountID !== locationState.activeUserAccountID;
    if (shouldStoreUserAccountID) {
      let userAccountIDtoStore = activeUserAccountID;
      if (routerLocation.pathname.includes("/admin")) userAccountIDtoStore = "moov-admin";
      void navigate(routerLocation, {
        replace: true,
        state: { ...locationState, activeUserAccountID: userAccountIDtoStore }
      });
    } else if (shouldRetrieveUserAccountID) {
      if (locationState.activeUserAccountID)
        setActiveUserAccountIDWithSuper(locationState.activeUserAccountID);
    }
  }, [userAccounts, routerLocation, activeUserAccountID]);
}
